<template>
  <v-chip v-bind="$attrs" :color="color" outlined>
    <strong>{{ estado }}</strong>
  </v-chip>
</template>

<script>
const estados = {
  "PENDIENTE DOCUMENTO": "#c89faa",
  "PENDIENTE COMPROBACIÓN": "#c8929a",
  "PENDIENTE PAGO": "#db94a5",
  PAGADA: "#e1b097",
};

export default {
  props: {
    estado: String,
  },
  computed: {
    color() {
      return estados[this.estado] || "";
    },
  },
};
</script>

<style></style>
